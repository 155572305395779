<template>
  <div class="quesList">
    <div class="btns">
      <el-button type="primary" class="childBtn" @click="handleCreate">
        <i class="el-icon-document-add"></i>
        创建问卷
      </el-button>
      <el-dropdown>
        <el-button type="primary" class="childBtn">
          <i class="el-icon-edit-outline"></i>
          设计问卷<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleDesign">问卷设置</el-dropdown-item>
          <el-dropdown-item @click.native="handleDesign">问卷规则设置</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button class="childBtn" @click="handleShare">
        <i class="el-icon-s-promotion"></i>
        分享问卷
      </el-button> -->
      <el-button class="childBtn" @click="handleDownload">
        <i class="el-icon-s-data"></i>
        查看下载答案
      </el-button>
      <!-- <el-dropdown class="childBtn" @command="handleDownload">
        <el-button>
          <i class="el-icon-s-data"></i>
          分析下载
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">统计与分析</el-dropdown-item>
          <el-dropdown-item command="b">查看下载答案</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
    <table-list
      title="问卷列表"
      :loading="loading"
      :search-form="searchForm"
      :data="QuestionList"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :options="{ singleCurrent: true, fixScrollTable: true }"
      @search="onSearch"
      @singleSelectChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <shareCode ref="code"></shareCode>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = [
  {
    label: '问卷ID',
    prop: 'surveyId',
  },
  {
    label: '问卷名称',
    prop: 'surveyTitle',
  },
  {
    label: '创建时间',
    type: 'picker',
    prop: 'createTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'surveyId',
    label: 'ID',
  },
  {
    prop: 'surveyTitle',
    label: '问卷名称',
  },
  {
    prop: 'questionCount',
    label: '题目数量',
    align: 'right',
  },
  {
    prop: 'submitCount',
    label: '完成次数',
    align: 'right',
  },
  {
    prop: 'userName',
    label: '创建人',
  },
  {
    prop: 'createTime',
    label: '创建时间',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '预览',
    method: _this.handleCheck,
  },
  {
    label: '发布',
    isShow: row => row.surveyStatus == 0,
    method: _this.handlePush,
  },
  {
    label: '停止',
    isShow: row => row.surveyStatus == 1,
    method: _this.handlePush,
  },
  {
    label: '删除',
    color: '#979797',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import { QuesList, QuesDelete, status } from '@/api/examination'
import shareCode from './tableQues/shareCode.vue'
import to from 'await-to'
export default {
  components: {
    TableList,
    shareCode,
  },
  data() {
    return {
      searchForm,
      columns,
      operates,
      loading: false,
      QuestionList: [],
      selectList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  mounted() {
    this.getQuesListData()
  },
  methods: {
    async getQuesListData() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(QuesList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.QuestionList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.createTime) {
        queryParams.createBeginTime = queryParams.createTime[0]
        let time = queryParams.createTime[1].split(' ')[0]
        let Time = '' + time + ' ' + '23:59:59' + ''
        queryParams.createEndTime = Time
      } else {
        queryParams.createBeginTime = ''
        queryParams.createEndTime = ''
      }
      this.queryParams = queryParams
      this.getQuesListData()
    },
    //创建问卷
    handleCreate() {
      this.$router.push('/examination/quesManger/add')
    },
    //设计问卷
    handleDesign() {
      if (this.selectList.length == 0) {
        return this.$message({ type: 'warning', message: '请选择问卷！' })
      }
      this.$router.push({
        path: '/examination/quesManger/setting',
        query: { id: this.selectList.surveyId },
      })
    },
    //分享问卷
    handleShare() {
      if (this.selectList.length == 0) {
        return this.$message({ type: 'warning', message: '请选择分享的问卷' })
      }
      this.$refs.code.show(this.selectList.surveyId, this.selectList.surveyTitle)
    },
    //分析与下载
    async handleDownload() {
      if (this.selectList.length == 0) {
        return this.$message({ type: 'warning', message: '请选择分享的问卷' })
      }
      this.$router.push({
        path: '/examination/quesManger/downLoad',
        query: { surveyId: this.selectList.surveyId },
      })
    },
    // async handleDownload(command) {
    //   if (command == 'b') {
    //     if (this.selectList.length == 0) {
    //       return this.$message({ type: 'warning', message: '请选择分享的问卷' })
    //     }
    //     this.$router.push({
    //       path: '/examination/quesManger/downLoad',
    //       query: { surveyId: this.selectList.surveyId },
    //     })
    //   }
    // },

    // 预览
    handleCheck(row) {
      this.$router.push({
        path: '/examination/quesManger/preview',
        query: { surveyId: row.surveyId, surveyTitle: row.surveyTitle },
      })
    },
    //发布
    async handlePush(row) {
      console.log(row)
      const [, err] = await to(status({ surveyId: row.surveyId, surveyStatus: row.surveyStatus }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('操作成功')
      location.reload()
    },
    //分配
    // handleDistribute(row) {
    //   console.log(row)
    // },
    // 删除
    async handleDelete(row) {
      this.$confirm('确认删除问卷吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(QuesDelete({ surveyId: row.surveyId }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除问卷成功')
        location.reload()
      })
    },
    //选中的列表
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getQuesListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getQuesListData()
    },
  },
}
</script>
<style scoped lang="scss">
.quesList {
  position: relative;
  .btns {
    position: absolute;
    right: 0;
    top: 98px;
  }
  .childBtn {
    margin: 0 10px;
  }
  .table-list {
    ::v-deep .form {
      .el-date-editor {
        width: 250px !important;
      }
    }
  }
}
</style>
