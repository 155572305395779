import { render, staticRenderFns } from "./shareCode.vue?vue&type=template&id=56cc43bc&scoped=true&"
import script from "./shareCode.vue?vue&type=script&lang=js&"
export * from "./shareCode.vue?vue&type=script&lang=js&"
import style0 from "./shareCode.vue?vue&type=style&index=0&id=56cc43bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56cc43bc",
  null
  
)

export default component.exports